import React, { PureComponent } from "react"
import { getScrollData } from './scroll-data'

export default class ScrollTracker extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      scrollPercentage: 0
    }
  }

  componentDidMount() {
    const that = this;
    window.addEventListener('scroll', () => {
      getScrollData(that);  
    });
  }

  componentWillUnmount() {
  }

  render() {
    const trackerStyle = {
      background: "#20a2dc",
      width: "4px",
      zIndex: 211,
      position: "fixed",
      top: "0px",
      right: "0px",
      height: "100%",
      transform: `scaleY(${this.state.scrollPercentage/100})`,
      transformOrigin: "top",
      transition: "all .3s ease-out",
    }

    return (
      <>
        <div id="scrollTracker" style={trackerStyle}></div>
      </>
    )
  }
}
