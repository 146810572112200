import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { Fragment } from "react"

import { createHtml } from "../../utils/utils"

import HamburgerIcon from "./HamburgerIcon.js"

var classNames = require("classnames")
class HamburgerMenu extends React.Component {
  constructor(props) {
    super(props)
    this.state = { width: 0, height: 0 }
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this)
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight })
  }

  // Generates keyframe style tag to be document.head.appendChild'ed;
  generateKeyframesStyle(width, height, modifier) {
    let radius = 5000
    if (width > height) {
      radius = width
    } else if (width < height) {
      radius = height
    } else {
      radius = width
    }

    // 2 is too low
    if (!modifier) {
      modifier = 2.5
    }

    radius = radius * modifier

    return `
    @keyframes menuAnimOpen {
      0% {
        top: ${-0.5 * radius}px;
        right: ${-0.5 * radius}px;
        width: 0;
        height: 0;
        border-radius: 50%;
      }
      100% {
        top: ${-0.5 * radius}px;
        right: ${-0.5 * radius}px;
        width: ${radius}px;
        height: ${radius}px;
        border-radius: 50%;
      }
    }
    
    @keyframes menuAnimClose {
      0% {
        top: ${-0.5 * radius}px;
        right: ${-0.5 * radius}px;
        width: ${radius}px;
        height: ${radius}px;
        border-radius: 50%;
      }

      100% {
        top: ${-0.5 * radius}px;
        right: ${-0.5 * radius}px;
        width: 0;
        height: 0;
        border-radius: 50%;
      }
    }`
  }

  componentDidMount() {
    this.updateWindowDimensions()
    window.addEventListener("resize", this.updateWindowDimensions)
    const menuKeyframes = this.generateKeyframesStyle(
      window.innerWidth,
      window.innerHeight
    )
    const $style = document.createElement("style")
    $style.innerHTML = menuKeyframes
    document.head.appendChild($style)
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions)
  }

  render() {
    let iconColor = "#6d6e6f"
    let iconClass = classNames({
      "hamburger-icon": true,
      open: this.props.isOpen,
    })
    //   if (this.props.atTopOfPage) {
    //   iconColor = "white"
    // }

    return (
      <div className="hamburger-menu-container">
        <Sidebar
          toggleMenu={this.props.toggleMenu}
          isOpen={this.props.isOpen}
          isClosing={this.props.isClosing}
          isOpening={this.props.isOpening}
          key="sidebar1"
          language={this.props.language}
          openMenuLeft={this.props.openMenuLeft}
          openMenuRight={this.props.openMenuRight}
          popularPages={this.props.popularPages}
        />
        <HamburgerIcon
          toggleMenu={this.props.toggleMenu}
          className={iconClass}
          color={iconColor}
          isOpen={this.props.isOpen}
          isClosing={this.props.isClosing}
          isOpening={this.props.isOpening}
          iconType={this.props.iconType}
          boldMenu={this.props.boldMenu}
          language={this.props.language}
        />
      </div>
    )
  }
}
HamburgerMenu.propTypes = {
  isOpen: PropTypes.bool,
  isOpening: PropTypes.bool,
  isClosing: PropTypes.bool,
  toggleMenu: PropTypes.func,
}

const Sidebar = props => {
  var sidebarClass = classNames({
    "hamburger-menu": true,
    columns: true,
    "is-vcentered": true,
    "hamburger-menu--closed": !props.isOpen,
    "hamburger-menu--open": props.isOpen,
    "hamburger-menu--closing": props.isClosing,
  })

  var menuContentClass = classNames({
    "hamburger-menu-content columns is-vcentered is-centered": true,
    shown: props.isOpen && !props.isOpening && !props.isClosing,
  })

  const handleSubmit = (e) => {
    e.preventDefault();
    document.getElementById("paymentForm").submit();
  }

  return (
    <div className={sidebarClass}>
      <div className="column is-fullwidth">
        <div className={menuContentClass}>
          <div className="column"></div>
          <ol className="column menu-list-1 is-narrow">
            {props.openMenuLeft.map((item, i) => (
              <Fragment key={i}>
                <li>
                  <Link
                    onClick={props.toggleMenu}
                    to={props.language === "es" ? item.urlEs : item.url}
                  >
                    {props.language === "es" ? item.titleEs : item.title}
                  </Link>
                </li>
                <br />
              </Fragment>
            ))}
          </ol>
          <div className="column is-2"></div>
          <div className="column is-2 vl"></div>
          <div className="column right-column is-narrow">
            <ul className="menu-list-2">
              {props.openMenuRight.map((item, i) => (
                <Fragment key={i}>
                <li key={props.language === "es" ? item.urlEs : item.url}>
                  {!item.opensNewTab && (
                    <Link
                      onClick={props.toggleMenu}
                      to={props.language === "es" ? item.urlEs : item.url}
                    >
                      {props.language === "es" ? item.titleEs : item.title}
                    </Link>
                  )}
                  {item.opensNewTab && (
                    <a
                      href={props.language === "es" ? item.urlEs : item.url}
                      target={item.opensNewTab ? "_blank" : ""}
                      rel="noopener noreferrer"
                      title=""
                    >
                      {props.language === "es" ? item.titleEs : item.title}
                    </a>
                  )}
                </li>
                </Fragment>
              ))}
            </ul>
            <div className="popular-pages">
              <div className="popular-pages-word">
                {props.language === "es"
                  ? "Páginas Populares"
                  : "Popular Pages"}
              </div>
              {props.popularPages.map((item, i) => (
                <Fragment key={i}>
                  {!item.opensNewTab && !(props.language === "es" && !item.existsOnSpanish) && (
                    <Link
                      onClick={props.toggleMenu}
                      to={props.language === "es" ? item.urlEs : item.url}
                      key={props.language === "es" ? item.urlEs : item.url}
                      dangerouslySetInnerHTML={createHtml(
                        props.language === "es" ? item.titleEs : item.title
                      )}
                    ></Link>
                  )}
                  {item.opensNewTab && !(props.language === "es" && !item.existsOnSpanish) && (
                    <a
                      href={props.language === "es" ? item.urlEs : item.url}
                      target={item.opensNewTab ? "_blank" : ""}
                      key={props.language === "es" ? item.urlEs : item.url}
                      rel="noopener noreferrer"
                      title=""
                      dangerouslySetInnerHTML={createHtml(
                        props.language === "es" ? item.titleEs : item.title
                      )}
                    >
                    </a>
                  )}
                  <br />
                </Fragment>
              ))}
            </div>
          </div>
          <div className="column"></div>
        </div>
      </div>
    </div>
  )
}
Sidebar.propTypes = {
  isOpen: PropTypes.bool,
  isOpening: PropTypes.bool,
  isClosing: PropTypes.bool,
}

export default HamburgerMenu
